import React, { useCallback, useEffect, useRef } from 'react'

import { Post } from '../Types/Post';

import { Box, IconButton } from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_POSTS } from '../Querys';

import { postQuerySerializer, usePostsFeed } from '../Components/PostsFeed/Providers/PostsProvider';

import { DATE_ORDER_BY, PreviewDateAttr, useFilters } from '../Components/PostsFeed/Components/Filters/FiltersProvider';
import { useFeedsState } from '../Components/PostsFeed/Providers/FeedsStateProvider';
import { useFeedIndex } from '../Components/PostsFeed/Providers/IndexProvider';

import Layout, { useLayout } from '../Components/Layout/Layout';
import { useNavigate } from 'react-router-dom';

import {
  Menu as MenuIcon,
} from "@mui/icons-material";
import Counter from '../Components/PostsFeed/Components/commons/Counter';
import SearchBar from '../Components/Layout/SearchBar';
import { BarCenterContent } from '../Components/Layout/BarCenterContent';
import { PostRow } from '../Components/PostsFeed/Components/PostPreview/PostPreview';
import { Virtuoso } from 'react-virtuoso'
import { CreateNewPostBtn } from '../Components/Layout/NavButtons';


const PostsFeedScreen = () => {

  // Auth we get the user information for this Screen
  const navigate = useNavigate();
  let scrollableRef = useRef<HTMLElement | Window | null>(null); // Referencia para el contenedor scrollable

  // Get the Index Context
  const { currentPostIndex, ref, virtuosoState } = useFeedIndex();

  // Using the App Filters.
  const [filters] = useFilters();

  // Current Active Feeds.
  const { getFeedVariables } = useFeedsState();

  // Getting Posts Data.
  const { posts, total, nextPost } = usePostsFeed();
  const { fetchMore, loading } = useQuery<{ posts: Post[] }>(GET_POSTS, {
    skip: true,
    variables: {
      ...postQuerySerializer(filters),
      ...getFeedVariables()
    }
  });

  // Memoriza el índice para evitar cálculos innecesarios
  const index = React.useMemo(() => posts.findIndex(post => post.id === currentPostIndex), [posts, currentPostIndex]);

  useEffect(() => {
    const currentRef = ref.current; // Capturamos una referencia estable al valor actual

    return () => {
      currentRef?.getState((snapshot) => {
        virtuosoState.current = snapshot;
      });
    };
  }, []); // Dependencias vacías aseguran que el efecto solo se ejecute al desmontar


  useEffect(() => {
    const handleKeyDownOnNewsFeed = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        nextPost();
        navigate(`/news/${currentPostIndex}`);
        event.preventDefault();
      }
    };

    if (currentPostIndex !== null) document.addEventListener('keydown', handleKeyDownOnNewsFeed);

    return () => {
      document.removeEventListener('keydown', handleKeyDownOnNewsFeed);
    }

  }, [currentPostIndex, navigate, nextPost]);


  // Handling Post Click, If its the current Index set the index to the next post.
  // TODO: Handle This on the PostEditor so it get set to the next post when unmount.
  // TODO: Handle if the current index is the last, set it the pick the pass
  const onClickRow = useCallback((post: Post) => {
    nextPost(post);
    navigate(`/news/${post.id}`);
  }, [navigate, nextPost]);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.focus();
    }
  }, []);

  const loadMore = useCallback(() => {
    if (posts.length < total)
      return fetchMore({
        variables: {
          offset: posts.length,
          limit: 200,
        },
      });
  }, [posts.length, total, fetchMore]);


  const [key, setKey] = React.useState(0);
  console.log(posts)
  console.log("Current Post Index", index, "Id:", currentPostIndex, "\nPost:", posts.find(p => p.id === currentPostIndex), "\nposts:", posts, "\n Virtuoso State", virtuosoState.current);

  return (
    <Layout menu={<Menu />}>
      <Box sx={{ height: '100%' }}>
        <Virtuoso
          ref={ref}
          scrollerRef={(ref) => { scrollableRef.current = ref }}
          style={{ height: '100%' }}
          totalCount={posts.length}
          data={posts}
          context={{ loading, loadMore }}
          restoreStateFrom={virtuosoState.current}
          endReached={loadMore}
          components={{ Footer }}
          itemContent={(index, post) =>
            <PostRow
              post={post}
              selected={post.id === currentPostIndex}
              dateField={getOrderDateBy(filters.orderDatesBy)}
              onClick={onClickRow}
            />}
        />
      </Box>
    </Layout>
  )
};

function getOrderDateBy(orderDatesBy: DATE_ORDER_BY): PreviewDateAttr {
  switch (orderDatesBy.split('|')[0]) {
    case 'news_date':
      return 'news_date';
    case 'created_at':
      return 'created_at';
    default:
      return 'updated_at';
  }
}

const Footer = ({ context: { loadMore, loading } }: any) => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <button disabled={loading} onClick={loadMore}>
        {loading ? 'Loading...' : 'Press to load more'}
      </button>
    </div>
  )
}

const Menu = () => {
  const { setIsDrawerOpen, isSearchByClouseOpen, setIsSearchByClouseOpen } = useLayout();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => {
            setIsDrawerOpen(isOpen => !isOpen);
          }} >
            <MenuIcon />
          </IconButton >

          <Counter />
        </Box>
        <BarCenterContent />
        <CreateNewPostBtn />
      </Box>

      {/* Search row  */}
      {
        isSearchByClouseOpen && <SearchBar onClose={() => setIsSearchByClouseOpen(false)} />
      }
    </Box>
  )
}

export default PostsFeedScreen;